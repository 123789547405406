import { NotFound } from 'components/Common/NotFound'
import { ResolveCart } from 'components/ResolveCart/ResolveCart'
import { useAuraOAuth } from 'components/Rewards/hooks/useAuraOAuth'
import { useLoyaltyLinkMembership } from 'components/Rewards/hooks/useLoyaltyLinkMembership'
import { VenueLanding } from 'components/Venue/VenueLanding'
import { VenuePage } from 'components/Venue/VenuePage'
import { VenuePageLoader } from 'components/Venue/VenuePageLoader'
import { OrderingTypeContext, VenueContext } from 'contexts/VenueOrderContext'
import useLogOnMount from 'hooks/useLogOnMount'
import {
  PartnerLoginContext,
  useLegacyLoginFallback,
  WithPartnerLogin,
} from 'hooks/usePartnerLogin'
import {
  Partner,
  VenueIndexDocument,
  VenueIndexQuery,
  VenueIndexQueryVariables,
} from 'lib/gql'
import { getOrderingTypeFromSlug, OrderingTypeSlug } from 'lib/routes'
import { CartDefaults } from 'lib/utils'
import { urqlClient } from 'lib/withUrql'
import { NextPage } from 'next'
import { NextUrqlPageContext } from 'next-urql'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { CustomerStoreContext } from 'stores/CustomerStore'
import {
  getDefaultOrderingTypeSlug,
  getNestedVenueSlug,
  getTableInfo,
} from 'utils/venue'

interface Props {
  error?: Error | null
  data?: VenueIndexQuery
  venueSlug: string
  orderingTypeSlug: OrderingTypeSlug
}

const Venue: NextPage<CartDefaults & Props> = ({
  venueSlug,
  orderingTypeSlug,
  tableArea,
  tableNumber,
  error,
  data,
}) => {
  useLogOnMount('View venue categories')
  const intl = useIntl()
  useAuraOAuth({ venueSlug })
  useLoyaltyLinkMembership()
  const { viewedOrderingTypeModal } = useContext(CustomerStoreContext)
  const { showLoader, legacyLogin } = useLegacyLoginFallback()

  if (!data?.venueIndex || error) {
    return (
      <NotFound
        message={intl.formatMessage(
          {
            defaultMessage: 'Venue {venueSlug} not found',
            id: '2R567s',
          },
          { venueSlug },
        )}
        error={error}
      />
    )
  }

  const venueIndex = data.venueIndex

  return (
    <>
      <VenueContext.Provider value={{ venueSlug }}>
        <OrderingTypeContext.Provider
          value={{
            orderingTypeSlug: orderingTypeSlug,
            orderingType: getOrderingTypeFromSlug(orderingTypeSlug),
          }}
        >
          <VenueLanding>
            {showLoader ? (
              <VenuePageLoader categoriesCount={6} />
            ) : (
              <WithPartnerLogin
                useLegacyLogin={legacyLogin}
                partner={Partner.Eonx}
              >
                <PartnerLoginContext.Consumer>
                  {({ partnerResolved }) => (
                    <>
                      {!partnerResolved ? (
                        <VenuePageLoader categoriesCount={6} />
                      ) : (
                        <ResolveCart
                          tableArea={tableArea}
                          tableNumber={tableNumber}
                          loader={<VenuePageLoader categoriesCount={6} />}
                        >
                          <VenuePage
                            orderingTypePrompt={
                              !!venueIndex.showOrderingTypePicker &&
                              !viewedOrderingTypeModal
                            }
                          />
                        </ResolveCart>
                      )}
                    </>
                  )}
                </PartnerLoginContext.Consumer>
              </WithPartnerLogin>
            )}
          </VenueLanding>
        </OrderingTypeContext.Provider>
      </VenueContext.Provider>
    </>
  )
}

Venue.getInitialProps = async (ctx: NextUrqlPageContext) => {
  const venueSlug = getNestedVenueSlug(ctx.query) ?? ''

  const { data, error } = await urqlClient(venueSlug, ctx.req, ctx.res)
    .query<VenueIndexQuery>(
      VenueIndexDocument,
      {
        venueSlug,
      } as VenueIndexQueryVariables,
      {
        requestPolicy: 'network-only',
      },
    )
    .toPromise()

  const venue = data?.venueIndex
  const orderingType = venue?.availableOrderingType

  return {
    venueSlug,
    error,
    data,
    orderingTypeSlug: getDefaultOrderingTypeSlug(orderingType),
    ...getTableInfo(ctx.query),
  }
}

export default Venue
