import * as React from 'react'

function SvgSnapScan(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      id="SnapScan_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 216 138"
      ref={svgRef}
      {...props}
    >
      <defs>
        <clipPath id="SnapScan_svg__clip-path">
          <path
            d="M-69-118.71h-32.36a6.89 6.89 0 01-7-6.8v-20.75a6.89 6.89 0 016.88-6.89H-69a6.88 6.88 0 017 6.8v20.79a6.88 6.88 0 01-6.92 6.85"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
        <style>{'.SnapScan_svg__cls-4{fill:#fff}'}</style>
      </defs>
      <path
        d="M216 17.09v-1.86a27.58 27.58 0 00-.36-4.05 13.4 13.4 0 00-1.27-3.85A13 13 0 00212 4a13.1 13.1 0 00-3.29-2.38A13.47 13.47 0 00204.8.39a27.71 27.71 0 00-4.06-.39H15.26a27.71 27.71 0 00-4.06.39 13.47 13.47 0 00-3.86 1.27 12.9 12.9 0 00-5.68 5.67 13.4 13.4 0 00-1.27 3.85A27.58 27.58 0 000 15.23V122.77a27.58 27.58 0 00.36 4 13.57 13.57 0 001.27 3.86A13.09 13.09 0 004.05 134a12.69 12.69 0 003.29 2.38 13.47 13.47 0 003.86 1.27 27.71 27.71 0 004.06.36H200.74a27.71 27.71 0 004.06-.36 13.47 13.47 0 003.86-1.27A12.86 12.86 0 00212 134a13.09 13.09 0 002.39-3.28 13.57 13.57 0 001.27-3.86 27.58 27.58 0 00.36-4V121 19.31c-.02-.74-.02-1.48-.02-2.22z"
        fill="#3c78bc"
        fillRule="evenodd"
      />
      <path
        className="SnapScan_svg__cls-4"
        d="M108 14.81A54.19 54.19 0 10162.19 69 54.19 54.19 0 00108 14.81zM156.81 69A48.81 48.81 0 11108 20.18 48.81 48.81 0 01156.81 69z"
      />
      <path
        className="SnapScan_svg__cls-4"
        d="M100.92 46H88.51A3.54 3.54 0 0085 49.54v12.38a3.53 3.53 0 003.53 3.54h12.41a3.54 3.54 0 003.54-3.54V49.54a3.55 3.55 0 00-3.56-3.54M127.49 46h-12.41a3.55 3.55 0 00-3.54 3.54v12.38a3.54 3.54 0 003.54 3.54h12.41a3.54 3.54 0 003.51-3.54V49.54a3.55 3.55 0 00-3.51-3.54M100.92 72.53H88.51A3.54 3.54 0 0085 76.07v12.38A3.53 3.53 0 0088.51 92h12.41a3.54 3.54 0 003.54-3.54V76.07a3.55 3.55 0 00-3.54-3.54"
      />
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgSnapScan)
export default ForwardRef
