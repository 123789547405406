import * as React from 'react'

function SvgAfterpay(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      id="Afterpay_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 216 138"
      ref={svgRef}
      {...props}
    >
      <defs>
        <clipPath id="Afterpay_svg__clip-path">
          <path
            d="M-69 231.84h-32.36a6.9 6.9 0 01-7-6.8v-20.75a6.9 6.9 0 016.88-6.9H-69a6.87 6.87 0 017 6.79V225a6.89 6.89 0 01-6.92 6.86"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
        <style />
      </defs>
      <path
        d="M216 17.09v-1.86a27.58 27.58 0 00-.36-4.05 13.4 13.4 0 00-1.27-3.85A13 13 0 00212 4a13.1 13.1 0 00-3.29-2.38A13.47 13.47 0 00204.8.39a27.71 27.71 0 00-4.06-.39H15.26a27.71 27.71 0 00-4.06.39 13.47 13.47 0 00-3.86 1.27 12.9 12.9 0 00-5.68 5.67 13.4 13.4 0 00-1.27 3.85A27.58 27.58 0 000 15.23V122.77a27.58 27.58 0 00.36 4 13.57 13.57 0 001.27 3.86A13.09 13.09 0 004.05 134a12.69 12.69 0 003.29 2.38 13.47 13.47 0 003.86 1.27 27.71 27.71 0 004.06.36H200.74a27.71 27.71 0 004.06-.36 13.47 13.47 0 003.86-1.27A12.86 12.86 0 00212 134a13.09 13.09 0 002.39-3.28 13.57 13.57 0 001.27-3.86 27.58 27.58 0 00.36-4V121 19.31c-.02-.74-.02-1.48-.02-2.22z"
        fill="#b2fce4"
        fillRule="evenodd"
      />
      <path d="M203.69 57.92l-5.92-3.4-5.91-3.52a6 6 0 00-8.93 5.19v.72a1.28 1.28 0 00.56 1.06l2.79 1.57a1.12 1.12 0 001.53-.39v-.06a1.06 1.06 0 000-.56V56.7a1.17 1.17 0 011.19-1.23h.68l5.58 3.18 5.58 3.12a1.25 1.25 0 01.39 1.68l-.39.39-5.63 3.16-5.58 3.18a1.18 1.18 0 01-1.62-.38 1.27 1.27 0 010-.67v-.89a6 6 0 00-8.93-5.24l-6 3.46-5.91 3.4a6 6 0 00-2.26 8.14 5.5 5.5 0 002.23 2.23l5.91 3.41 6 3.4a6 6 0 008.95-5.18v-.72a1.28 1.28 0 00-.56-1.06l-2.79-1.62a1.11 1.11 0 00-1.53.39v.05a1.3 1.3 0 000 .62v1.78a1.17 1.17 0 01-1.12 1.23 1.52 1.52 0 01-.67 0l-5.58-3.18-5.58-3.12a1.24 1.24 0 01-.61-1.57l.39-.39 5.58-3.12 5.56-3.15a1.17 1.17 0 011.61.38 1.52 1.52 0 010 .67v.89a6 6 0 008.92 5.19l6-3.46 5.92-3.4a5.92 5.92 0 001.93-8.14v-.07a5.7 5.7 0 00-1.78-2.14zM164.74 58.93l-13.9 28.68h-5.58l5.19-10.71-8.14-18h5.91l5.25 12 5.58-12zM25.85 69.08a5.58 5.58 0 10-5.58 5.86 5.58 5.58 0 005.58-5.58v-.28m0 10.21v-2.67a8.32 8.32 0 01-6.47 3 10 10 0 01-9.76-10.2v-.34a10.15 10.15 0 019.75-10.54 8.18 8.18 0 016.36 3v-2.61h5v20.36zM55.26 74.66c-1.78 0-2.26-.66-2.26-2.34V63.5h3.18v-4.4H53V54h-5.1v5h-6.7v-2.14c0-1.67.67-2.34 2.45-2.34h1.12v-4h-2.4c-4.24 0-6.25 1.39-6.25 5.58v2.73h-2.84v4.67h2.84v16h5.08v-16h6.7v10c0 4.19 1.56 6 5.58 6h2.79v-4.8zM73.67 67.24a4.85 4.85 0 00-5.07-4.18 5 5 0 00-5.14 4.18zm-10.15 3.18a5.08 5.08 0 005.24 4.75 5.57 5.57 0 004.8-2.63h5.25a10 10 0 01-10.16 7.09 10 10 0 01-10.71-9.32v-1.23a10.55 10.55 0 1121.09 0 4.64 4.64 0 010 1.29zM111.84 69.08a5.58 5.58 0 100 .28v-.28M95.72 87.61V58.93h5v2.62a8.31 8.31 0 016.47-3A10 10 0 01117 68.69v.39a10.15 10.15 0 01-9.75 10.55A8 8 0 01101 76.9v10.71zM135.05 69.08a5.58 5.58 0 10-5.86 5.86h.28a5.58 5.58 0 005.58-5.58v-.28m0 10.21v-2.67a8.13 8.13 0 01-6.41 3 10 10 0 01-9.82-10.15v-.39a10.15 10.15 0 019.75-10.54 8.08 8.08 0 016.31 3v-2.61h5v20.36zM86.68 60.94a5 5 0 014.4-2.4 5 5 0 012.18.44v5.25a6.44 6.44 0 00-3.63-1 3.58 3.58 0 00-2.79 4v12.06h-5.19V58.93h5z" />
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgAfterpay)
export default ForwardRef
