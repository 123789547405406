import {
  OrderingType,
  PaymentFormQuery,
  PaymentProcessorType,
  VenueFieldRequirement,
} from 'lib/gql'
import find from 'lodash/find'
import qs from 'query-string'
import { IntlShape } from 'react-intl'

import {
  PaymentMethodKey,
  PaymentMethodValue,
  UsePaymentMethodsReturnType,
} from './hooks/usePaymentFormMethods'
import { PaymentState } from './PaymentController/PaymentController'
import { getPaymentSchema } from './PaymentSchema'

export const getValidationSchema = (
  {
    paymentFormData,
    orderingType,
    showEmailFieldWhenDineIn,
  }: {
    paymentFormData?: PaymentFormQuery
    orderingType: OrderingType
    showEmailFieldWhenDineIn: boolean
  },
  intl: IntlShape,
) => {
  const venue = paymentFormData?.guestVenue

  const orderingTypeSettings = venue?.orderingTypes.find(
    (setting) => setting.orderingType === orderingType,
  )

  const emailFieldRequirement =
    orderingTypeSettings?.emailFieldRequirement ||
    VenueFieldRequirement.Optional
  const coverCountFieldRequirement =
    orderingTypeSettings?.coverCountFieldRequirement ||
    VenueFieldRequirement.Hidden

  return getPaymentSchema(
    {
      paymentFormData,
      hasOptions: !!(
        orderingTypeSettings?.options &&
        orderingTypeSettings?.options.length > 0
      ),
      orderingType,
      emailFieldRequirement,
      coverCountFieldRequirement,
      showEmailFieldWhenDineIn,
    },
    intl,
  )
}

export const getTabById = (
  id: string | undefined,
  paymentFormData?: PaymentFormQuery,
) => {
  const tabs = paymentFormData?.currentUser?.tabs || []
  return find(tabs, { id })
}

export const getSubmitButtonLabel = (
  {
    paymentMethod,
    paymentState,
    paymentFormData,
  }: {
    paymentMethod: PaymentMethodValue | null
    paymentState: PaymentState[] | undefined
    paymentFormData?: PaymentFormQuery
  },
  intl: IntlShape,
) => {
  const isNewPaymentMethod = (paymentState || []).includes('new')
  const isNewTab = (paymentState || []).includes('tab')

  if (
    paymentMethod?.key.match(PaymentMethodKey.StripeNewCard) &&
    !isNewPaymentMethod
  ) {
    return intl.formatMessage({
      defaultMessage: 'Add a new card',
      id: 'lrfCD6',
    })
  }

  if (
    paymentMethod?.key.match(PaymentMethodKey.PaystackNewCard) &&
    !isNewPaymentMethod
  ) {
    return intl.formatMessage({
      defaultMessage: 'Add a new card',
      id: 'lrfCD6',
    })
  }

  if (paymentMethod?.key.match(PaymentMethodKey.TabNew)) {
    return intl.formatMessage({ defaultMessage: 'Continue', id: 'acrOoz' })
  }

  if (paymentMethod?.key.match(PaymentMethodKey.Snapscan)) {
    return intl.formatMessage({
      defaultMessage: 'Pay with Snapscan',
      id: 'YGSalD',
    })
  }

  if (paymentMethod?.key.match(PaymentMethodKey.Afterpay)) {
    return intl.formatMessage({
      defaultMessage: 'Pay with Afterpay',
      id: 'TfYxi0',
    })
  }

  if (paymentMethod?.key.match(PaymentMethodKey.Unpaid)) {
    return (
      paymentFormData?.guestVenue?.paymentProcessor?.unpaidProcessorTypeLabel ||
      intl.formatMessage({ defaultMessage: 'Pay later', id: 'ppn572' })
    )
  }

  if (isNewTab) {
    return intl.formatMessage({
      defaultMessage: 'Start group order',
      id: 'w9prxu',
    })
  }

  return intl.formatMessage({ defaultMessage: 'Pay now', id: '7tOz+m' })
}

export const getPathAfterpay = (url: string, message: string) =>
  qs.stringifyUrl({
    url,
    query: {
      afterpay: message,
      orderToken: undefined,
      status: undefined,
    },
  })

// https://stripe.com/docs/statement-descriptors#requirements
// Duplicate from stringUtils back-end
export const stripeStatementDescriptor = (name: string) => {
  return name
    .replace(/[^A-Za-z0-9\s]/g, '')
    .trim()
    .replace(/\s\s/g, ' ')
    .substring(0, 22)
    .toUpperCase()
}

export const isAnonymousGuest = (
  user: PaymentFormQuery['currentUser'],
): boolean => {
  return user?.mobile?.startsWith('+69') ?? false
}

export const getPaymentMethod = ({
  key,
  paymentMethods,
}: { key: string | PaymentMethodKey | null } & Pick<
  UsePaymentMethodsReturnType,
  'paymentMethods'
>): PaymentMethodValue | null => {
  if (key) {
    const paymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.key === key,
    )

    // Loke doesn't exist in payment methods atm.
    return paymentMethod
      ? paymentMethod
      : key === PaymentMethodKey.Loke
        ? {
            key: PaymentMethodKey.Loke,
            value: null,
            tracking: 'Loke',
            processorType: PaymentProcessorType.Loke,
          }
        : paymentMethods[0]
  } else {
    return paymentMethods[0]
  }
}
