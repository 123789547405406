import * as React from 'react'

function SvgChargeToRoom(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 216 138"
      ref={svgRef}
      className="text-[theme(backgroundColor.interactive-accent-surface-active)]"
      {...props}
    >
      <path
        d="M216 17.09v-1.86a27.58 27.58 0 00-.36-4.05 13.4 13.4 0 00-1.27-3.85A13 13 0 00212 4a13.1 13.1 0 00-3.29-2.38A13.47 13.47 0 00204.8.39a27.71 27.71 0 00-4.06-.39H15.26a27.71 27.71 0 00-4.06.39 13.47 13.47 0 00-3.86 1.27 12.9 12.9 0 00-5.68 5.67 13.4 13.4 0 00-1.27 3.85A27.58 27.58 0 000 15.23v107.54a27.58 27.58 0 00.36 4 13.57 13.57 0 001.27 3.86A13.09 13.09 0 004.05 134a12.69 12.69 0 003.29 2.38 13.47 13.47 0 003.86 1.27 27.71 27.71 0 004.06.36h185.48a27.71 27.71 0 004.06-.36 13.47 13.47 0 003.86-1.27A12.86 12.86 0 00212 134a13.09 13.09 0 002.39-3.28 13.57 13.57 0 001.27-3.86 27.58 27.58 0 00.36-4V19.31c-.02-.74-.02-1.48-.02-2.22z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <g fill="#fff">
        <path d="M67.566 74.765h80.865a6.035 6.035 0 100-12.069H67.566a6.035 6.035 0 100 12.07zM114.033 49.68a3.198 3.198 0 00-3.194 3.194v4.14h24.72v-4.14a3.198 3.198 0 00-3.194-3.194z" />
        <path d="M74.757 52.874c0-4.894 3.981-8.875 8.875-8.875h18.332a8.84 8.84 0 016.035 2.375A8.84 8.84 0 01114.033 44h18.332c4.894 0 8.875 3.981 8.875 8.875v4.141h9.22V37.449a6.035 6.035 0 00-6.034-6.034H71.57a6.035 6.035 0 00-6.034 6.034v19.566h9.22z" />
        <path d="M83.632 49.68a3.198 3.198 0 00-3.194 3.194v4.14h24.72v-4.14a3.198 3.198 0 00-3.194-3.194zM59.52 80.447v26.136h5.681V96.225h85.595v10.358h5.681V80.447z" />
      </g>
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgChargeToRoom)
export default ForwardRef
