import { PaymentProcessorType } from 'lib/gql'
import some from 'lodash/some'
import * as Yup from 'yup'

import { PaymentMethodKey } from './hooks/usePaymentFormMethods'

/*
  These are the payment methods where we access paymentMethod.value in order to process transaction.
  These should ideally be static values that don't change at runtime after we first load data and
  set initial form values.
*/
export const paymentMethodKeysRequiringValue = [
  PaymentMethodKey.StripeExistingCard,
  PaymentMethodKey.PaystackExistingCard,
  PaymentMethodKey.Snapscan,
  PaymentMethodKey.TabExisting,
]

//TO DO: add translation.

export const paymentMethodSchema = Yup.object().shape({
  paymentMethod: Yup.object()
    .shape({
      key: Yup.string(),
      value: Yup.mixed().when('key', {
        is: (key: string) =>
          some(paymentMethodKeysRequiringValue, (k) => key?.match(k)),
        then: Yup.mixed().required('Payment method value is required'),
        otherwise: Yup.mixed().nullable(),
      }),
      tracking: Yup.string().nullable(),
      processorType: Yup.mixed<PaymentProcessorType>()
        .oneOf(Object.values(PaymentProcessorType), 'No payment type selected')
        .notOneOf(
          [PaymentProcessorType.Braintree],
          'Braintree is no longer supported',
        ),
    })
    .nullable()
    .required(),
})
