import { isAnonymousGuest } from 'components/Payment/utils'
import { useCurrentUser } from 'hooks/useCurrentUser'
import Cookies from 'js-cookie'
import { useTryLinkGuestToMembershipMutation } from 'lib/gql'
import { useEffect, useMemo, useState } from 'react'

export const REWARD_MEMBERSHIP_TOKEN_KEY = 'reward-membership-token'

export const useLoyaltyLinkMembership = () => {
  const [linkAttempted, setLinkAttempted] = useState(false)
  const { currentUser } = useCurrentUser()

  const [, link] = useTryLinkGuestToMembershipMutation()
  const linkGuestToMembership = useMemo(() => {
    return async ({
      membershipToken,
      guestId,
    }: {
      membershipToken: string
      guestId: string
    }) => {
      return link({
        linkMembershipData: {
          membershipToken,
          guestId,
        },
      })
    }
  }, [link])

  useEffect(() => {
    if (linkAttempted || (currentUser && isAnonymousGuest(currentUser))) {
      return
    }

    const membershipTokenInCookieStorage = Cookies.get(
      REWARD_MEMBERSHIP_TOKEN_KEY,
    )

    if (!currentUser?.id || !membershipTokenInCookieStorage) {
      return
    }

    void linkGuestToMembership({
      membershipToken: membershipTokenInCookieStorage,
      guestId: currentUser.id,
    })
    Cookies.remove(REWARD_MEMBERSHIP_TOKEN_KEY)
    setLinkAttempted(true)

    return () => {
      Cookies.remove(REWARD_MEMBERSHIP_TOKEN_KEY)
    }
  }, [linkGuestToMembership, linkAttempted, currentUser])

  return null
}
